import { useQuery } from 'react-query';
import axiosClient from './axiosClient';

export const dashboardApi = {
  progressChart(params) {
    return axiosClient
      .get('/admin/dashboard/progress-chart', { params, timeout: 3000000 })
      .then((response) => response?.data);
  },
  performanceProgress(params) {
    return axiosClient
      .get('/admin/dashboards/implementation-progress', {
        params,
        timeout: 3000000,
      })
      .then((response) => response?.data);
  },
  getMapData(params) {
    return axiosClient
      .get('/admin/map/survey-result', { params, timeout: 3000000 })
      .then((response) => response?.data);
  },
  getTracking(params) {
    return axiosClient
      .get('/admin/tracking', { params, timeout: 3000000 })
      .then((response) => response?.data);
  },
  getMilkGroupChart(params) {
    return axiosClient
      .get('/admin/dashboard/milk-group-chart', { params, timeout: 3000000 })
      .then((response) => response?.data);
  },
  getPosmChart(params) {
    return axiosClient
      .get('/admin/dashboard/posm-chart', { params, timeout: 3000000 })
      .then((response) => response?.data);
  },
  getBrandChart(params) {
    return axiosClient
      .get('/admin/dashboard/brand-chart', { params, timeout: 3000000 })
      .then((response) => response?.data);
  },
  getZoneTypeSizeChart(params) {
    return axiosClient
      .get('/admin/dashboard/zone-type-size-chart', {
        params,
        timeout: 3000000,
      })
      .then((response) => response?.data);
  },
};

export const useProgressChart = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'progress-chart', params],
    queryFn: () => dashboardApi.progressChart(params),
    ...options,
  });
};

export const usePerformanceProgress = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'performance-progress', params],
    queryFn: () => dashboardApi.performanceProgress(params),
    ...options,
  });
};

export const useGetMapData = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'map', params],
    queryFn: () => dashboardApi.getMapData(params),
    ...options,
  });
};

export const useGetTracking = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'tracking', params],
    queryFn: () => dashboardApi.getTracking(params),
    ...options,
  });
};

export const useMilkGroupChart = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'milk-group-chart', params],
    queryFn: () => dashboardApi.getMilkGroupChart(params),
    ...options,
  });
};

export const usePosmChart = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'posm-chart', params],
    queryFn: () => dashboardApi.getPosmChart(params),
    ...options,
  });
};

export const useBrandChart = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'brand-chart', params],
    queryFn: () => dashboardApi.getBrandChart(params),
    ...options,
  });
};

export const useZoneTypeSizeChart = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'zone-type-size-chart', params],
    queryFn: () => dashboardApi.getZoneTypeSizeChart(params),
    ...options,
  });
};
