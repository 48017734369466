import { useMutation, useQuery } from 'react-query';
import axiosClient from './axiosClient';

export const fileApi = {
  create(data) {
    return axiosClient.post('/admin/files', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  delete({ id, params }) {
    return axiosClient.delete(`/admin/files/${id}`, { params });
  },
  getFileGroups(params) {
    return axiosClient.get('/file/groups', { params });
  },
  rotate({ id, rotate }) {
    return axiosClient.patch(
      `/admin/files/${id}/rotate`,
      {},
      { params: { rotate } },
    );
  },
};

export const useCreateFile = () => {
  return useMutation(fileApi.create);
};

export const useDeleteFile = () => {
  return useMutation(fileApi.delete);
};

export const useFileGroups = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['file', 'groups', params],
    queryFn: () => fileApi.getFileGroups(params),
    ...options,
  });
};

export const useRotateFile = () => {
  return useMutation(fileApi.rotate);
};
