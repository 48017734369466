import { useEffect } from 'react';
import { StyledFormItem, StyledInputNumber } from 'styles/overrides';
import { formatInputNumber, parseInputNumber } from 'utils/formatUtils';
import { Form } from 'antd';
import { usePlanSurveyContext } from 'context/PlanSurveyContext';
import _ from 'lodash';

export default function RenderInputNumber({ component, isInputPrice }) {
  const {
    id,
    name,
    required,
    disable,
    related_question,
    related_question_id,
    related_question_ids,
    bin_to_batch,
    disable_question_id,
  } = component;
  const { onUpdateQuestions } = usePlanSurveyContext();
  const formInstance = Form.useFormInstance();
  const watchAllFields = Form.useWatch([], formInstance);
  // const watchSelectField = Form.useWatch([id, 'content'], formInstance);
  const watchSelectField = _.get(watchAllFields, [id, 'content']);
  const watchedRelatedQuestionIds =
    related_question_ids &&
    _.pick(
      watchAllFields,
      _.map(related_question_ids, (value, key) => key),
    );

  useEffect(() => {
    if (!watchedRelatedQuestionIds) {
      return;
    }

    if (related_question === 'convert_bin_to_batch') {
      const number = _.reduce(
        related_question_ids,
        (total, value, key) => {
          const inputValue = _.get(watchedRelatedQuestionIds[key], ['content']);
          let convertedNum = 0;

          if (value === 'THUNG') {
            convertedNum = (+inputValue || 0) * bin_to_batch;
          } else if ((value = 'LOC')) {
            convertedNum = +inputValue || 0;
          }

          return total + convertedNum;
        },
        0,
      );

      formInstance.setFieldValue([id, 'content'], number);
    } else if (related_question === 'calculate_average_monthly') {
      const averageNumber = _.reduce(
        watchedRelatedQuestionIds,
        (total, item) => {
          const num = +item?.content || 0;

          return total * num;
        },
        1,
      );

      formInstance.setFieldValue([id, 'content'], averageNumber);
    }
  }, [related_question, JSON.stringify(watchedRelatedQuestionIds)]);

  useEffect(() => {
    if (related_question !== 'bin_batch') {
      return;
    }

    if (!disable_question_id) {
      return;
    }

    onUpdateQuestions({
      questions: disable_question_id,
      data: {
        disable: +!!watchSelectField,
        required: +!watchSelectField,
      },
    });
  }, [watchSelectField]);

  return (
    <StyledFormItem
      label={name}
      name={[id, 'content']}
      rules={[{ required: !!required, message: 'Bắt buộc' }]}
    >
      <StyledInputNumber
        min={0}
        controls={false}
        formatter={isInputPrice ? formatInputNumber : undefined}
        parser={isInputPrice ? parseInputNumber : undefined}
        disabled={!!disable}
      />
    </StyledFormItem>
  );
}
