import { useMemo } from 'react';
import { Column } from '@ant-design/plots';
import _ from 'lodash';

export default function ColumnChart({ total, data = [] }) {
  const config = useMemo(() => {
    return {
      data,
      xField: 'name',
      yField: 'value',
      seriesField: 'name',
      color: _.map(data, (item) => item.color),
      xAxis: {
        label: {
          autoHide: false,
          autoRotate: true,
          style: { fontSize: 10 },
        },
      },
      minColumnWidth: 20,
      maxColumnWidth: 40,
      legend: false,
      annotations: _.map(data, (item) => ({
        type: 'text',
        content: `${item.value}`,
        style: { textAlign: 'center', fontSize: 14, fill: 'var(--black)' },
        offsetY: -10,
        position: [item.name, item.value],
      })),
      padding: [35, 35, 100, 35],
    };
  }, [data]);

  return <Column {...config} />;
}
