import { useIntl } from 'react-intl';
import { Table } from 'components/table';
import { StyledSelect } from 'styles/overrides';

export default function PerformanceProgressTable({
  dataSource = [],
  isLoading = false,
}) {
  const intl = useIntl();

  const columns = [
    {
      title: intl.formatMessage({
        id: 'views.dashboard.performanceProgress.table.role',
      }),
      dataIndex: ['roles', 0, 'name'],
    },
    {
      title: intl.formatMessage({
        id: 'views.dashboard.performanceProgress.table.user',
      }),
      render: ({ full_name, name, versions }) => (
        <>
          <p>{full_name}</p>
          <p>{name}</p>
          {versions?.length > 0 && (
            <StyledSelect defaultValue={versions[0]}>
              {versions.map((v) => (
                <StyledSelect.Option key={v} value={v}>
                  {v}
                </StyledSelect.Option>
              ))}
            </StyledSelect>
          )}
        </>
      ),
      width: 200,
    },
    {
      title: intl.formatMessage({
        id: 'views.dashboard.performanceProgress.table.doing',
      }),
      dataIndex: ['plans_doing'],
    },
    {
      title: intl.formatMessage({
        id: 'views.dashboard.performanceProgress.table.today',
      }),
      dataIndex: ['plan_done_today'],
    },
    {
      title: intl.formatMessage({
        id: 'views.dashboard.performanceProgress.table.progress',
      }),
      dataIndex: ['plan_done'],
      render: (value) => `${value}/${value}`,
    },
    {
      title: intl.formatMessage({
        id: 'views.dashboard.performanceProgress.table.progressPercent',
      }),
      dataIndex: ['plan_percent'],
      render: (value) => `${value}%`,
    },
    {
      title: intl.formatMessage({
        id: 'views.dashboard.performanceProgress.table.unsuccessful',
      }),
      render: ({ plan_unsuccessful, plan_unsuccessful_percent }) =>
        `${plan_unsuccessful} - ${plan_unsuccessful_percent}%`,
    },
    {
      title: intl.formatMessage({
        id: 'views.dashboard.performanceProgress.table.qcDone',
      }),
      children: [
        {
          title: intl.formatMessage({
            id: 'views.dashboard.performanceProgress.table.allQCs',
          }),
          dataIndex: ['plan_qc'],
        },
        {
          title: intl.formatMessage({
            id: 'views.dashboard.performanceProgress.table.supQC',
          }),
          dataIndex: ['plan_sup_qc'],
        },
        {
          title: intl.formatMessage({
            id: 'views.dashboard.performanceProgress.table.repair',
          }),
          dataIndex: ['plan_repair'],
        },
        {
          title: intl.formatMessage({
            id: 'views.dashboard.performanceProgress.table.no_repair',
          }),
          dataIndex: ['plan_yet_confirm'],
        },
        {
          title: intl.formatMessage({
            id: 'views.dashboard.performanceProgress.table.errors',
          }),
          dataIndex: ['plan_tick_code'],
        },
      ],
    },
    {
      title: intl.formatMessage({
        id: 'views.dashboard.performanceProgress.table.rewardPhoneCard',
      }),
      dataIndex: ['reward_phone_card'],
    },
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={dataSource}
      loading={isLoading}
      pagination={false}
      expandable={false}
      rowClassName={(record) => record.isBold && 'table-row-bold'}
      scroll={{ y: '50vh', x: 'max-content' }}
    />
  );
}
