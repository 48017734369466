import { PreviewImages } from 'components/common';
import { usePlanSurveyContext } from 'context/PlanSurveyContext';

export default function RenderCamera({ images = [] }) {
  const { onDeleteFile, onRotateImage } = usePlanSurveyContext();

  return (
    <PreviewImages
      images={images}
      onRotateImage={onRotateImage}
      onDeleteImage={onDeleteFile}
    />
  );
}
