import { StyledFormItem, StyledInput } from 'styles/overrides';

export default function RenderInputText({ component }) {
  const { id, name, required, disable } = component;

  return (
    <StyledFormItem
      label={name}
      name={[id, 'content']}
      rules={[{ required: !!required, message: 'Bắt buộc' }]}
    >
      <StyledInput disabled={!!disable} />
    </StyledFormItem>
  );
}
