export const PATH_NAME = {
  ROOT: '/',
  ADMIN: '/admin',
  USERS: 'users',
  STORES: 'stores',
  PLANS: 'plans',
  EXPORTS: 'exports',
  REQUEST_PERMISSION_EXPORT: 'request-permission-export',
  DASHBOARD: 'dashboard',
  DASHBOARD_PROGRESS: 'progress',
  DASHBOARD_PERFORMANCE_PROGRESS: 'performance-progress',
  DASHBOARD_RESULT_CHART: 'result',
  DASHBOARD_MAP: 'map',
  DASHBOARD_SURVEY: 'survey',
  PHONE_CARDS: 'phone-cards',

  CUSTOMER_ORDER: '/orders',
  LOGIN: '/login',
  ORDERS: 'customer-orders',
  PRODUCTS: 'products',
  CUSTOMERS: 'customers',
  LOGS: 'logs',
  SETTINGS: 'settings',
  PROFILE: 'profile',
  USER: 'user',
  SHOPS: 'shops',
};

export const PATH_NAME_ACTION = {
  EDIT: 'edit',
};
