import React from 'react';
import { Row, Col } from 'antd';
import _ from 'lodash';
import { StyledCard, StyledFormItem } from 'styles/overrides';
import RenderComponent from '../RenderComponent/RenderComponent';
import RenderCamera from '../RenderCamera/RenderCamera';

export default function RenderBlock({ block }) {
  const { id, name, questions, camera, is_show, is_show_admin } = block;
  const isShow = !_.isNil(is_show_admin)
    ? is_show_admin
    : !_.isNil(is_show)
    ? is_show
    : true;

  if (!isShow) {
    return null;
  }

  return (
    <StyledCard title={name} key={id} size="small">
      <Row gutter={[24, 24]}>
        <Col span={camera ? 12 : 24}>
          <div>
            {questions?.groups?.length > 0 && (
              <>
                {_.map(questions.groups, (group) => (
                  <RenderBlock key={group.id} block={group} />
                ))}
              </>
            )}

            {questions.length > 0 &&
              _.map(questions, (question) => (
                <RenderComponent
                  key={question.id}
                  component={question}
                  className="highlight"
                />
              ))}
          </div>
        </Col>

        {camera && (
          <Col span={12}>
            <StyledFormItem
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.images !== currentValues.images
              }
            >
              {({ getFieldValue }) => {
                const images =
                  _.get(getFieldValue('images'), [
                    camera?.group_id,
                    'images',
                  ]) ?? [];

                return <RenderCamera images={images} />;
              }}
            </StyledFormItem>
          </Col>
        )}
      </Row>
    </StyledCard>
  );
}
