import { useIntl } from 'react-intl';
import { StyledFormItem, StyledButton, StyledSpace } from 'styles/overrides';
import { formatTime } from 'utils/timeUtils';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';
import { FILE_TYPE } from 'constants/file';
import _ from 'lodash';
import UploadAudio from '../UploadAudio';
import { usePlanSurveyContext } from 'context/PlanSurveyContext';

export default function RenderAudio({ id }) {
  const intl = useIntl();
  const { onDeleteFile, onAddFile } = usePlanSurveyContext();

  const handleDelete = (audioId) => {
    onDeleteFile?.(audioId, FILE_TYPE.AUDIO);
  };

  return (
    <StyledFormItem
      noStyle
      shouldUpdate={(prevValues, currentValues) =>
        prevValues.audios !== currentValues.audios
      }
    >
      {({ getFieldValue }) => {
        const currentAudio = _.get(getFieldValue('audios'), [id]);

        if (!currentAudio) {
          return (
            <Permission roles={[ROLE.ADMIN, ROLE.PL]}>
              <StyledFormItem>
                <UploadAudio
                  onSubmit={(values) =>
                    onAddFile?.({ ...values, questionId: id })
                  }
                />
              </StyledFormItem>
            </Permission>
          );
        }

        const { path, created_at, id: audioId } = currentAudio;
        const createdAt = formatTime(created_at);

        return (
          <StyledFormItem
            label={
              <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC, ROLE.SUP]}>
                {createdAt}
              </Permission>
            }
          >
            <StyledSpace>
              <audio controls>
                <source src={path} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>

              <Permission roles={[ROLE.ADMIN, ROLE.PL]}>
                <StyledButton
                  type="danger"
                  onClick={() => handleDelete(audioId)}
                >
                  {intl.formatMessage({ id: 'common.btn.delete' })}
                </StyledButton>
              </Permission>
            </StyledSpace>
          </StyledFormItem>
        );
      }}
    </StyledFormItem>
  );
}
