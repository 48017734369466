import _ from 'lodash';
import { useIntl } from 'react-intl';
import { Spin, Divider, Typography, List, Row, Col } from 'antd';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import {
  useMilkGroupChart,
  usePosmChart,
  useBrandChart,
  useZoneTypeSizeChart,
} from 'api/dashboardApi';
import ResultChartFilterSearch from '../ResultChartFilterSearch/ResultChartFilterSearch';
import { StyledSpace, StyledList } from 'styles/overrides';
import DonutChart from '../../DonutChart/DonutChart';
import ColumnChart from '../../ColumnChart/ColumnChart';

export default function ResultChart() {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();
  const { data, isLoading, isFetching } = useMilkGroupChart({ params: search });
  const {
    data: posm,
    isLoading: isLoadingPosm,
    isFetching: isFetchingPosm,
  } = usePosmChart({ params: search });
  const {
    data: brandChart,
    isLoading: isLoadingBrandChart,
    isFetching: isFetchingBrandChart,
  } = useBrandChart({ params: search });
  const {
    data: zoneTypeSizeChart,
    isLoading: isLoadingZoneTypeSizeChart,
    isFetching: isFetchingZoneTypeSizeChart,
  } = useZoneTypeSizeChart({ params: search });

  return (
    <>
      <ResultChartFilterSearch
        onSubmit={(values) => setSearch(values)}
        initialValues={search}
      />

      <Spin
        spinning={
          isLoading ||
          isFetching ||
          isLoadingPosm ||
          isFetchingPosm ||
          isLoadingBrandChart ||
          isFetchingBrandChart ||
          isLoadingZoneTypeSizeChart ||
          isFetchingZoneTypeSizeChart
        }
      >
        <StyledSpace
          direction="vertical"
          split={
            <Divider
              type="horizontal"
              style={{ borderWidth: 2, borderColor: 'var(--black)' }}
            />
          }
        >
          {_.map(data, (value, key) => {
            return (
              <div key={key}>
                <Typography.Title level={3} type="danger">
                  {intl.formatMessage({
                    id: `views.dashboard.resultChart.title.${key}`,
                  })}
                </Typography.Title>

                <StyledList
                  grid={{
                    gutter: 16,
                    column: 5,
                  }}
                  dataSource={value}
                  renderItem={(item) => (
                    <List.Item>
                      <DonutChart item={item} />
                    </List.Item>
                  )}
                />
              </div>
            );
          })}

          <Row gutter={[24, 24]}>
            {_.map(zoneTypeSizeChart, (value, key) => (
              <Col span={24} sm={8} key={key}>
                <DonutChart
                  item={{
                    ...value,
                    name: intl.formatMessage({
                      id: `views.dashboard.resultChart.title.${key}`,
                    }),
                  }}
                />
              </Col>
            ))}
          </Row>

          <Row gutter={[24, 24]}>
            {_.map(brandChart, (value, key) => {
              return (
                <Col span={12} key={key}>
                  <Typography.Title level={3} type="danger">
                    {intl.formatMessage({
                      id: `views.dashboard.resultChart.title.${key}`,
                    })}
                  </Typography.Title>

                  <ColumnChart data={value.details} total={value.total_value} />
                </Col>
              );
            })}
          </Row>

          <Row gutter={[24, 24]}>
            {_.map(posm, (value, key) => {
              return (
                <Col span={24} sm={12} key={key}>
                  <Typography.Title level={3} type="danger">
                    {intl.formatMessage({
                      id: `views.dashboard.resultChart.title.${key}`,
                    })}
                  </Typography.Title>

                  <ColumnChart data={value.details} total={value.total_value} />
                </Col>
              );
            })}
          </Row>
        </StyledSpace>
      </Spin>
    </>
  );
}
