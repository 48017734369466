import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Modal, Typography } from 'antd';
import { calcIndexByOrder, removeFalsyKeys } from 'utils/common';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { Table } from 'components/table';
import { formatTime } from 'utils/timeUtils';
import { StyledActions, StyledButton } from 'styles/overrides';
import PhoneCardFilterSearch from '../PhoneCardFilterSearch';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';
import { Upload } from 'components/upload';
import { DownloadTemplate } from 'components/download-template';
import { PATH_NAME } from 'constants/routes';
import {
  usePhoneCards,
  useDownloadTemplateImportPhoneCards,
  useImportPhoneCards,
  useExportPhoneCards,
  useRestorePhoneCards,
  useDeletePhoneCards,
} from 'api/phoneCardApi';
import { Notification } from 'components/Notification';
import PhoneCardAddUsers from '../PhoneCardAddUsers';
import PhoneCardRevoke from '../PhoneCardRevoke';

export default function PhoneCardTable() {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();
  const { data, isLoading, refetch, isFetching } = usePhoneCards({
    params: search,
  });
  const downloadTemplateImportMutation = useDownloadTemplateImportPhoneCards();
  const importMutation = useImportPhoneCards();
  const exportMutation = useExportPhoneCards({ params: search });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const restoreMutation = useRestorePhoneCards();
  const deleteMutation = useDeletePhoneCards();

  const columns = [
    {
      title: intl.formatMessage({ id: 'common.table.no' }),
      render: (text, record, index) => {
        return calcIndexByOrder({
          index,
          page: search.page,
          limit: search.page_size,
        });
      },
    },
    {
      title: intl.formatMessage({ id: 'views.phoneCards.table.user' }),
      dataIndex: ['user'],
      render: (value) => (value ? `${value.name} - ${value.full_name}` : null),
    },
    {
      title: intl.formatMessage({ id: 'views.phoneCards.table.code' }),
      dataIndex: ['code'],
    },
    {
      title: intl.formatMessage({ id: 'common.table.status' }),
      dataIndex: ['used'],
      render: (value) => (
        <Typography.Paragraph
          type={!!value ? 'success' : 'danger'}
          style={{ marginBottom: 0 }}
        >
          {intl.formatMessage({ id: `common.status.phoneCard.${value}` })}
        </Typography.Paragraph>
      ),
    },
    {
      title: intl.formatMessage({ id: 'common.table.createdAt' }),
      dataIndex: 'created_at',
      render: (value) => formatTime(value),
    },
    {
      title: intl.formatMessage({ id: 'views.phoneCards.table.plan' }),
      dataIndex: ['plan_id'],
      render: (id) =>
        !!id && (
          <Link to={`/${PATH_NAME.PLANS}/${id}`} target="_blank">
            <StyledButton type="primary">
              {intl.formatMessage({ id: 'common.btn.seeDetails' })}
            </StyledButton>
          </Link>
        ),
    },
  ];

  const handleChangeTable = ({ current: page, pageSize: limit }) => {
    setSearch(removeFalsyKeys({ ...search, page, page_size: limit }));
  };

  const handleRestore = () => {
    if (selectedRowKeys.length <= 0) return;

    Modal.confirm({
      title: intl.formatMessage({ id: 'common.modal.restoreTitle' }),
      okText: intl.formatMessage({ id: 'common.btn.confirm' }),
      onOk() {
        return restoreMutation.mutateAsync(selectedRowKeys, {
          onSuccess() {
            refetch();
            setSelectedRowKeys([]);
            Notification(
              'success',
              intl.formatMessage({ id: 'message.commonSuccess' }),
            );
          },
          onError(error) {
            Notification(
              'error',
              error?.message ||
                intl.formatMessage({ id: 'message.commonError' }),
            );
          },
        });
      },
      onCancel() {},
    });
  };

  const handleDelete = () => {
    if (selectedRowKeys.length <= 0) return;

    Modal.confirm({
      title: intl.formatMessage({ id: 'common.modal.deleteTitle' }),
      okText: intl.formatMessage({ id: 'common.btn.confirm' }),
      onOk() {
        return deleteMutation.mutateAsync(selectedRowKeys, {
          onSuccess() {
            refetch();
            setSelectedRowKeys([]);
            Notification(
              'success',
              intl.formatMessage({ id: 'message.commonSuccess' }),
            );
          },
          onError(error) {
            Notification(
              'error',
              error?.message ||
                intl.formatMessage({ id: 'message.commonError' }),
            );
          },
        });
      },
      onCancel() {},
    });
  };

  return (
    <>
      <PhoneCardFilterSearch
        onSubmit={(values) => setSearch(values)}
        initialValues={search}
      />

      <Permission roles={[ROLE.PL]}>
        <StyledActions size={24}>
          <PhoneCardAddUsers
            onSuccess={refetch}
            numberOfRemainingCards={data?.total_not_yet_issued}
          />

          <StyledButton
            size="large"
            type="danger"
            onClick={handleDelete}
            disabled={selectedRowKeys.length <= 0}
          >
            {intl.formatMessage({ id: 'common.btn.delete' })}
          </StyledButton>

          <PhoneCardRevoke onSuccess={refetch} />

          <StyledButton
            size="large"
            type="danger"
            onClick={handleRestore}
            disabled={selectedRowKeys.length <= 0}
          >
            {intl.formatMessage({ id: 'common.btn.restore' })}
          </StyledButton>

          <Upload mutation={importMutation} onSuccess={refetch} />

          <DownloadTemplate mutation={downloadTemplateImportMutation} />

          <DownloadTemplate
            mutation={exportMutation}
            title={intl.formatMessage({ id: 'common.btn.export' })}
          />
        </StyledActions>
      </Permission>

      <Table
        rowKey="id"
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys) => {
            setSelectedRowKeys(newSelectedRowKeys);
          },
          preserveSelectedRowKeys: true,
        }}
        columns={columns}
        dataSource={data?.data || []}
        loading={isLoading || isFetching}
        pagination={{
          current: search?.page,
          pageSize: search?.page_size,
          total: data?.meta?.total || 0,
        }}
        onChange={handleChangeTable}
      />
    </>
  );
}
