import { useIntl } from 'react-intl';
import { StyledCard } from 'styles/overrides';
import { PreviewImages } from 'components/common';
import { usePhoneCardImages } from 'api/planApi';
import { Col, Spin, Typography } from 'antd';
import { useRotateFile } from 'api/fileApi';
import { Notification } from 'components/Notification';
import { formatTime } from 'utils/timeUtils';

export default function PlanPhoneCardImages({ planId }) {
  const intl = useIntl();
  const rotateMutation = useRotateFile();
  const { data, isLoading, isFetching, refetch } = usePhoneCardImages({
    id: planId,
  });

  const handleRotateImage = (id, rotate) => {
    rotateMutation.mutate(
      { id, rotate: -rotate },
      {
        onSuccess: () => {
          refetch();
        },
        onError: (error) => {
          Notification(
            'error',
            error.message ||
              intl.formatMessage({
                id: 'message.commonError',
              }),
          );
        },
      },
    );
  };

  if (isLoading || !data) {
    return null;
  }

  return (
    <Col span={24}>
      <Spin spinning={isFetching || rotateMutation.isLoading}>
        <StyledCard
          title={intl.formatMessage({
            id: 'views.plans.phoneCardImages.title',
          })}
          size="small"
        >
          <div style={{ marginBottom: 8 }}>
            <Typography.Title level={4} style={{ marginBottom: 0 }}>
              Mã: {data.code}
            </Typography.Title>
            {data.used_at && (
              <Typography.Text>
                Thời gian: {formatTime(data.used_at)}
              </Typography.Text>
            )}
          </div>

          <PreviewImages
            images={data.files ?? []}
            onRotateImage={handleRotateImage}
          />
        </StyledCard>
      </Spin>
    </Col>
  );
}
