import { createContext, useContext } from 'react';

const PlanSurveyContext = createContext(null);

export const PlanSurveyContextProvider = ({
  children,
  onShowHideQuestions,
  onUpdateQuestions,
  onDeleteFile,
  onAddFile,
  onRotateImage,
}) => {
  return (
    <PlanSurveyContext.Provider
      value={{
        onShowHideQuestions,
        onUpdateQuestions,
        onDeleteFile,
        onAddFile,
        onRotateImage,
      }}
    >
      {children}
    </PlanSurveyContext.Provider>
  );
};

export const usePlanSurveyContext = () => {
  return useContext(PlanSurveyContext);
};
