import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { useStoreStatuses } from 'api/storeApi';
import { StyledSelect } from 'styles/overrides';
import { filterOptions } from 'utils/formatUtils';

export default function StoreStatusChildrenSelector({
  storeStatus,
  onChange,
  value,
  ...restProps
}) {
  const intl = useIntl();
  const { data, isLoading } = useStoreStatuses();

  const mappingData = useMemo(() => {
    return _.reduce(
      data,
      (acc, item) => {
        acc[item.key] =
          _.map(item.store_status_children?.items, ({ key, name }) => ({
            label: name,
            value: key,
          })) ?? [];

        return acc;
      },
      {},
    );
  }, [data]);

  return (
    <StyledSelect
      options={mappingData[storeStatus]}
      loading={isLoading}
      showSearch
      optionFilterProp="label"
      filterOption={filterOptions}
      placeholder={intl.formatMessage({
        id: 'common.placeholder.storeStatusChildren',
      })}
      {...restProps}
      onChange={onChange}
      value={value}
    />
  );
}
