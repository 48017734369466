import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const userApi = {
  getAll(params) {
    return axiosClient.get('/admin/users', { params });
  },
  getById(id) {
    return axiosClient.get(`/admin/users/${id}`);
  },
  create(data) {
    return axiosClient.post('/admin/users', data);
  },
  update(id, data) {
    return axiosClient.patch(`/admin/users/${id}`, data);
  },
  delete(data) {
    return axiosClient.delete('/admin/users', { data });
  },
  getRoles(params) {
    return axiosClient.get('/admin/roles', { params });
  },
  downloadTemplateImport(params) {
    return axiosClient.get('/admin/users/example-template', { params });
  },
  import(data) {
    return axiosClient.post('/admin/users/import', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export const useUsers = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['users', 'list', params],
    queryFn: () => userApi.getAll(params),
    ...options,
  });
};

export const useUserRoles = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['users', 'roles', params],
    queryFn: () => userApi.getRoles(params),
    ...options,
  });
};

export const useCreateUser = () => {
  return useMutation(userApi.create);
};

export const useUpdateUser = (id) => {
  return useMutation((data) => userApi.update(id, data));
};

export const useDeleteUser = () => {
  return useMutation(userApi.delete);
};

export const useDownloadTemplateImport = () => {
  return useMutation(userApi.downloadTemplateImport);
};

export const useImportUsers = () => {
  return useMutation(userApi.import);
};
