import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  StyledButton,
  StyledCard,
  StyledForm,
  StyledFormItem,
  StyledInputTextArea,
} from 'styles/overrides';
import { Descriptions, Spin, Form } from 'antd';
import { formatTime } from 'utils/timeUtils';
import { Notification } from 'components/Notification';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';
import {
  StoreStatusSelector,
  UserSelector,
  StoreStatusChildrenSelector,
} from 'components/selectors';
import { useUpdatePlan } from 'api/planApi';
import { TEXT_DEFAULT } from 'constants/common';

export default function PlanInfo({
  version,
  deviceModel,
  user,
  isDone,
  qcStatus,
  storeStatus,
  storeStatusChildren,
  checkin,
  checkout,
  storeNote,
  planId,
  lat,
  long,
  isLoading = false,
}) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const updatePlanMutation = useUpdatePlan({ planId });
  const userId = user?.id?.toString();
  const storeStatusKey = storeStatus?.key;

  useEffect(() => {
    form.setFieldsValue({
      user_id: userId,
      store_note: storeNote,
      store_status: storeStatusKey,
      store_status_children: storeStatusChildren?.key,
    });
  }, [storeStatusKey, userId, storeNote, form, storeStatusChildren]);

  const handleUpdatePlan = (values) => {
    updatePlanMutation.mutate(values, {
      onSuccess() {
        Notification(
          'success',
          intl.formatMessage({ id: 'message.commonSuccess' }),
        );
      },
      onError(error) {
        Notification(
          'error',
          error?.message || intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  const handleValuesChange = (changedValues) => {
    if (changedValues['store_status']) {
      form.setFieldsValue({
        store_status_children: null,
      });
    }
  };

  return (
    <Spin spinning={updatePlanMutation.isLoading || isLoading}>
      <StyledForm
        form={form}
        onFinish={handleUpdatePlan}
        onValuesChange={handleValuesChange}
      >
        <StyledCard
          title={intl.formatMessage({ id: 'views.plans.planInfo.title' })}
          bordered={false}
          bodyStyle={{ padding: 0 }}
          size="small"
          extra={
            <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC]}>
              <StyledButton type="danger" htmlType="submit">
                {intl.formatMessage({ id: 'common.btn.save' })}
              </StyledButton>
            </Permission>
          }
        >
          <Descriptions column={1} bordered size="small">
            <Descriptions.Item
              label={intl.formatMessage({
                id: 'views.plans.planInfo.userInfo',
              })}
            >
              <Permission roles={[ROLE.PL]} noAccess={user?.full_name}>
                <StyledFormItem name="user_id" style={{ marginBottom: 0 }}>
                  <UserSelector params={{ role: ROLE.USER, page_size: 1000 }} />
                </StyledFormItem>
              </Permission>
            </Descriptions.Item>

            <Descriptions.Item
              label={intl.formatMessage({
                id: 'views.plans.planInfo.deviceModel',
              })}
            >
              {deviceModel}
            </Descriptions.Item>

            <Descriptions.Item
              label={intl.formatMessage({
                id: 'views.plans.planInfo.appVersion',
              })}
            >
              {version}
            </Descriptions.Item>

            <Descriptions.Item
              label={intl.formatMessage({
                id: 'views.plans.planInfo.planStatus',
              })}
            >
              {intl.formatMessage({
                id: `common.status.plan.${isDone}`,
              })}
            </Descriptions.Item>

            <Descriptions.Item
              label={intl.formatMessage({
                id: 'views.plans.planInfo.storeStatus',
              })}
            >
              <Permission roles={[ROLE.PL]} noAccess={storeStatus?.name}>
                <StyledFormItem name="store_status" style={{ marginBottom: 0 }}>
                  <StoreStatusSelector
                    placeholder={intl.formatMessage({
                      id: 'common.placeholder.status',
                    })}
                  />
                </StyledFormItem>
              </Permission>
            </Descriptions.Item>

            <Descriptions.Item
              label={intl.formatMessage({
                id: 'views.plans.planInfo.storeStatusChildren',
              })}
            >
              <Permission
                roles={[ROLE.PL]}
                noAccess={storeStatusChildren?.name}
              >
                <StyledFormItem
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.store_status !== currentValues.store_status
                  }
                >
                  {({ getFieldValue }) => {
                    const storeStatus = getFieldValue('store_status');

                    return (
                      <StyledFormItem
                        name="store_status_children"
                        style={{ marginBottom: 0 }}
                      >
                        <StoreStatusChildrenSelector
                          storeStatus={storeStatus}
                        />
                      </StyledFormItem>
                    );
                  }}
                </StyledFormItem>
              </Permission>
            </Descriptions.Item>

            <Descriptions.Item
              label={intl.formatMessage({
                id: 'views.plans.planStore.storeLocation',
              })}
            >
              {lat ?? TEXT_DEFAULT.NA}, {long ?? TEXT_DEFAULT.NA}
            </Descriptions.Item>

            <Descriptions.Item
              label={intl.formatMessage({
                id: 'views.plans.planInfo.qcStatus',
              })}
            >
              {intl.formatMessage({ id: `common.status.qc.${qcStatus}` })}
            </Descriptions.Item>

            <Descriptions.Item
              label={intl.formatMessage({
                id: 'views.plans.planInfo.startEndTime',
              })}
            >
              {formatTime(checkin)} / {formatTime(checkout)}
            </Descriptions.Item>

            <Descriptions.Item
              label={intl.formatMessage({
                id: 'views.plans.planInfo.storeNote',
              })}
            >
              <Permission
                roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC]}
                noAccess={storeNote}
              >
                <StyledFormItem name="store_note" style={{ marginBottom: 0 }}>
                  <StyledInputTextArea />
                </StyledFormItem>
              </Permission>
            </Descriptions.Item>
          </Descriptions>
        </StyledCard>
      </StyledForm>
    </Spin>
  );
}
