import React from 'react';
import _ from 'lodash';
import RenderInputJsonComponent from '../RenderInputJsonComponent/RenderInputJsonComponent';
import RenderTextInputFromToComponent from '../RenderTextInputFromToComponent/RenderTextInputFromToComponent';
import RenderSelectComponent from '../RenderSelectComponent/RenderSelectComponent';
import RenderRadioComponent from '../RenderRadioComponent/RenderRadioComponent';
import RenderGroupComponent from '../RenderGroupComponent/RenderGroupComponent';
import RenderInputNumber from '../RenderInputNumber/RenderInputNumber';
import RenderInputText from '../RenderInputText/RenderInputText';

export default function RenderComponent({ component, className }) {
  const { id, type, is_show, is_show_admin } = component;
  const isShow = !_.isNil(is_show_admin)
    ? is_show_admin
    : !_.isNil(is_show)
    ? is_show
    : true;

  if (!isShow) {
    return null;
  }

  switch (type) {
    case 'select':
    case 'select_and_input': {
      return (
        <RenderSelectComponent
          key={id}
          component={component}
          className={className}
        />
      );
    }

    case 'checkbox': {
      return (
        <RenderRadioComponent
          key={id}
          component={component}
          className={className}
        />
      );
    }

    case 'input_json': {
      return (
        <RenderInputJsonComponent
          key={id}
          component={component}
          className={className}
        />
      );
    }

    case 'input_json_from_to': {
      return <RenderTextInputFromToComponent key={id} component={component} />;
    }

    case 'input_price_group':
    case 'input_number_group':
    case 'checkbox_group':
    case 'select_group': {
      return <RenderGroupComponent key={id} component={component} />;
    }

    case 'input_price':
    case 'input_number': {
      return (
        <RenderInputNumber
          key={id}
          component={component}
          isInputPrice={type === 'input_price'}
        />
      );
    }

    case 'input_text': {
      return <RenderInputText key={id} component={component} />;
    }

    default: {
      return null;
    }
  }
}
