import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Typography } from 'antd';
import { StyledButton } from 'styles/overrides';
import { useResetFormOnCloseModal } from 'hooks/useResetFormOnCloseModal';
import { Modal } from 'components/modal/Modal';
import { useAddPhoneCardsToUsers } from 'api/phoneCardApi';
import { Notification } from 'components/Notification';
import PhoneCardAddUsersForm from '../PhoneCardAddUsersForm';

export default function PhoneCardAddUsers({
  onSuccess,
  numberOfRemainingCards = 0,
}) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);
  const mutation = useAddPhoneCardsToUsers();

  useResetFormOnCloseModal({
    form,
    open: isOpen,
  });

  const handleSubmit = (values) => {
    mutation.mutate(values, {
      onSuccess: () => {
        Notification(
          'success',
          intl.formatMessage({ id: 'message.commonSuccess' }),
        );

        setIsOpen(false);
        onSuccess?.();
      },
      onError: (error) => {
        Notification(
          'error',
          error.message ?? intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  return (
    <>
      <StyledButton
        size="large"
        type="primary"
        onClick={() => setIsOpen(true)}
        loading={mutation.isLoading}
      >
        {intl.formatMessage({ id: 'common.btn.phoneCardAddUsers' })}
      </StyledButton>

      <Modal
        title={intl.formatMessage({ id: 'common.modal.addNewTitle' })}
        onOk={() => {
          form
            .validateFields()
            .then(handleSubmit)
            .catch(() => {});
        }}
        okButtonProps={{ loading: mutation.isLoading }}
        onCancel={() => setIsOpen(false)}
        open={isOpen}
      >
        <Typography.Title level={4} type="danger">
          Còn lại {numberOfRemainingCards} thẻ
        </Typography.Title>

        <PhoneCardAddUsersForm form={form} />
      </Modal>
    </>
  );
}
