import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const phoneCardApi = {
  getAll(params) {
    return axiosClient.get('/admin/phone-cards', { params });
  },
  downloadTemplateImport(params) {
    return axiosClient.get('/admin/phone-cards/import/example-template', {
      params,
    });
  },
  import(data) {
    return axiosClient.post('/admin/phone-cards/import', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  export(params) {
    return axiosClient.get('/admin/exports/phone-cards', {
      params,
      timeout: 3000000,
    });
  },
  restore(ids) {
    return axiosClient.post('/admin/phone-cards/restore', { ids });
  },
  delete(ids) {
    return axiosClient.delete('/admin/phone-cards/delete/multiple', {
      data: { ids },
    });
  },
  addPhoneCardsToUsers(data) {
    return axiosClient.post('/admin/phone-cards/add-to-users', data);
  },
  revoke(data) {
    return axiosClient.post('/admin/phone-cards/revoke-from-users', data);
  },
};

export const usePhoneCards = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['phone-cards', 'list', params],
    queryFn: () => phoneCardApi.getAll(params),
    ...options,
  });
};

export const useDownloadTemplateImportPhoneCards = () => {
  return useMutation(phoneCardApi.downloadTemplateImport);
};

export const useImportPhoneCards = () => {
  return useMutation(phoneCardApi.import);
};

export const useExportPhoneCards = ({ params } = {}) => {
  return useMutation(() => phoneCardApi.export(params));
};

export const useRestorePhoneCards = () => {
  return useMutation({
    mutationFn: (data) => phoneCardApi.restore(data),
  });
};

export const useDeletePhoneCards = () => {
  return useMutation({
    mutationFn: (data) => phoneCardApi.delete(data),
  });
};

export const useAddPhoneCardsToUsers = () => {
  return useMutation({
    mutationFn: phoneCardApi.addPhoneCardsToUsers,
  });
};

export const useRevokePhoneCards = () => {
  return useMutation({
    mutationFn: phoneCardApi.revoke,
  });
};
