import { useMemo } from 'react';
import { StyledFormItem } from 'styles/overrides';
import RenderAudio from '../RenderAudio/RenderAudio';
import RenderComponent from '../RenderComponent/RenderComponent';
import _ from 'lodash';
import { Form } from 'antd';

export default function RenderGroupComponent({ component }) {
  const {
    id,
    name,
    required,
    is_record,
    question_children,
    related_question_ids,
    related_question,
    condition_related_question_ids,
  } = component;
  const formInstance = Form.useFormInstance();
  const watchAllFields = Form.useWatch([], formInstance);
  const watchedRelatedQuestionIds =
    related_question_ids &&
    _.pick(
      watchAllFields,
      _.map(related_question_ids, (value, key) => key),
    );

  const watchedConditionRelatedQuestionIds =
    condition_related_question_ids &&
    _.pick(
      watchAllFields,
      _.map(condition_related_question_ids, (value, key) => key),
    );

  const isHide = useMemo(() => {
    if (related_question === 'hide_question_group' && related_question_ids) {
      const isConditionValid = _.every(
        condition_related_question_ids,
        (value, key) => {
          const isChecking =
            !_.get(watchedConditionRelatedQuestionIds, [key, 'answer_id']) ||
            value ===
              _.get(watchedConditionRelatedQuestionIds, [key, 'answer_id']) ||
            value?.includes?.(
              _.get(watchedConditionRelatedQuestionIds, [key, 'answer_id']),
            );

          return isChecking;
        },
      );

      const isValid = _.every(related_question_ids, (value, key) => {
        const isChecked =
          !_.get(watchedRelatedQuestionIds, [key, 'answer_id']) ||
          value === _.get(watchedRelatedQuestionIds, [key, 'answer_id']) ||
          isConditionValid;

        return isChecked;
      });

      return isValid;
    }

    return false;
  }, [
    JSON.stringify(watchedRelatedQuestionIds),
    related_question,
    related_question_ids,
    JSON.stringify(watchedConditionRelatedQuestionIds),
  ]);

  if (isHide) {
    return null;
  }

  return (
    <div>
      <StyledFormItem
        label={name}
        labelCol={{ span: 12 }}
        className="highlight"
      >
        {/* TODO: Add or Delete audio */}
        {!!is_record && <RenderAudio id={id} />}
      </StyledFormItem>

      <div style={{ padding: '0 32px' }}>
        {_.map(question_children, (item) => (
          <RenderComponent key={item.id} component={item} />
        ))}
      </div>
    </div>
  );
}
