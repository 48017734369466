import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Row, Col, Switch } from 'antd';
import { VNMobilePhonePattern } from 'utils/validationUtils';
import {
  StyledForm,
  StyledFormItem,
  StyledInput,
  StyledInputPassword,
} from 'styles/overrides';
import RoleSelector from 'components/selectors/RoleSelector';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';
import { UserSelector } from 'components/selectors';

export default function AddEditUserForm({ form, initialValues }) {
  const intl = useIntl();
  const isEdit = !!initialValues;

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form]);

  return (
    <StyledForm layout="vertical" size="large" form={form}>
      <Row gutter={[32, 0]}>
        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.users.form.label.fullName',
            })}
            name="full_name"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.users.form.label.name',
            })}
            name="name"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.users.form.label.email',
            })}
            name="email"
            rules={[
              {
                type: 'email',
                message: intl.formatMessage({ id: 'validate.invalidEmail' }),
              },
            ]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.users.form.label.phone',
            })}
            name="phone"
            rules={[
              {
                pattern: VNMobilePhonePattern,
                message: intl.formatMessage({
                  id: 'validate.invalidPhoneNumber',
                }),
              },
            ]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.users.form.label.role',
            })}
            name="role"
            rules={[{ required: true }]}
          >
            <RoleSelector disabled={isEdit} />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.users.form.label.password',
            })}
            name="password"
            rules={[{ required: true }]}
          >
            <StyledInputPassword />
          </StyledFormItem>
        </Col>

        <StyledFormItem
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.role !== currentValues.role
          }
        >
          {({ getFieldValue }) =>
            getFieldValue('role') === ROLE.USER ? (
              <Col span={24} sm={12}>
                <StyledFormItem
                  label={intl.formatMessage({
                    id: 'views.users.form.label.sup',
                  })}
                  name="parent_id"
                  rules={[{ required: false }]}
                >
                  <UserSelector
                    allowClear
                    params={{ role: ROLE.SUP, page_size: 1000 }}
                    placeholder=""
                  />
                </StyledFormItem>
              </Col>
            ) : null
          }
        </StyledFormItem>

        {isEdit && (
          <Permission roles={[ROLE.PL]}>
            <Col span={24} sm={12}>
              <StyledFormItem
                label={intl.formatMessage({
                  id: 'views.users.form.label.active',
                })}
                name="active"
                rules={[{ required: true }]}
                valuePropName="checked"
              >
                <Switch />
              </StyledFormItem>
            </Col>
          </Permission>
        )}
      </Row>
    </StyledForm>
  );
}
