export const IMPORT_FILE_TYPES = ['csv', 'xls'];
export const EXPORT_FILE_TYPES = ['csv', 'xls'];

// export const FILE_TYPE = {
//   PNG: 'image/png',
//   JPEG: 'image/jpeg',
//   CSV: 'text/csv',
//   XLS: 'application/vnd.ms-excel',
// };

export const FILE_TYPE = {
  IMAGE: 'image',
  AUDIO: 'audio',
};
