import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const storeApi = {
  getAll(params) {
    return axiosClient.get('/admin/stores', { params });
  },
  getById(id) {
    return axiosClient.get(`/admin/stores/${id}`);
  },
  create(data) {
    return axiosClient.post('/admin/stores', data);
  },
  update(id, data) {
    return axiosClient.patch(`/admin/stores/${id}`, data);
  },
  delete(ids) {
    return axiosClient.delete('/admin/stores', { data: { ids } });
  },
  getStatuses(params) {
    return axiosClient.get('/stores/status', { params });
  },
  setAvatar(storeId, data) {
    return axiosClient.patch(`/admin/stores/${storeId}`, data);
  },
  import(data) {
    return axiosClient.post('/admin/stores/import', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  downloadTemplateImport(params) {
    return axiosClient.get('/admin/stores/import/example-template', { params });
  },
};

export const useStores = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['stores', 'list', params],
    queryFn: () => storeApi.getAll(params),
    ...options,
  });
};

export const useStore = ({ id, options } = {}) => {
  return useQuery({
    queryKey: ['stores', 'details', id],
    queryFn: () => storeApi.getById(id),
    ...options,
  });
};

export const useCreateStore = () => {
  return useMutation(storeApi.create);
};

export const useUpdateStore = (id) => {
  return useMutation((data) => storeApi.update(id, data));
};

export const useDeleteStore = () => {
  return useMutation(storeApi.delete);
};

export const useStoreStatuses = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['stores', 'statuses', params],
    queryFn: () => storeApi.getStatuses(params),
    ...options,
  });
};

export const useDeleteMultipleStores = () => {
  return useMutation({
    mutationFn: (ids) => storeApi.delete(ids),
  });
};

export const useSetStoreAvatar = (storeId) => {
  return useMutation({
    mutationFn: (data) => storeApi.setAvatar(storeId, data),
  });
};

export const useDownloadTemplateImportStores = () => {
  return useMutation(storeApi.downloadTemplateImport);
};

export const useImportStores = () => {
  return useMutation(storeApi.import);
};
