import { useMutation, useQuery } from 'react-query';
import axiosClient from './axiosClient';

export const exportApi = {
  getReport1(params) {
    return axiosClient.get('/admin/exports/report-1', {
      params,
      timeout: 3000000,
    });
  },
  getReport2(params) {
    return axiosClient.get('/admin/exports/report-2', {
      params,
      timeout: 3000000,
    });
  },
  getReportStores(params) {
    return axiosClient.get('/admin/exports/store', {
      params,
      timeout: 3000000,
    });
  },
  getReportQC(params) {
    return axiosClient.get('/admin/exports/report-qc', {
      params,
      timeout: 3000000,
    });
  },
  showPermission(params) {
    return axiosClient.get('/admin/exports/show-permission', { params });
  },
  requestPermission(params) {
    return axiosClient.get('/admin/exports/request-permission', { params });
  },
  verifyCodeGrantPermission(code) {
    return axiosClient.get(`/admin/exports/grant-permission/check/${code}`);
  },
  acceptGrantPermission(data) {
    return axiosClient.post('/admin/exports/grant-permission', data);
  },
  getImplementationProgress(params) {
    return axiosClient.get('/admin/exports/implementation-progress', {
      params,
      timeout: 3000000,
    });
  },
};

export const useGetReport1 = (params) => {
  return useMutation({
    mutationFn: () => exportApi.getReport1(params),
  });
};

export const useGetReport2 = (params) => {
  return useMutation({
    mutationFn: () => exportApi.getReport2(params),
  });
};

export const useGetReportStores = (params) => {
  return useMutation({
    mutationFn: () => exportApi.getReportStores(params),
  });
};

export const useGetReportQC = (params) => {
  return useMutation({
    mutationFn: () => exportApi.getReportQC(params),
  });
};

export const useShowPermissionExport = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['show-permissions', params],
    queryFn: () => exportApi.showPermission(params),
    ...options,
  });
};

export const useRequestPermissionExport = () => {
  return useMutation(exportApi.requestPermission);
};

export const useVerifyCodeGrantPermission = ({ code, options } = {}) => {
  return useQuery({
    queryKey: ['grant-permission', code],
    queryFn: () => exportApi.verifyCodeGrantPermission(code),
    ...options,
  });
};

export const useAcceptGrantPermission = () => {
  return useMutation(exportApi.acceptGrantPermission);
};

export const useGetExportImplementationProgress = (params) => {
  return useMutation({
    mutationFn: () => exportApi.getImplementationProgress(params),
  });
};
