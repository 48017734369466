import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Row, Col } from 'antd';
import {
  StyledForm,
  StyledFormItem,
  StyledInputNumber,
} from 'styles/overrides';
import { ROLE } from 'constants/roles';
import { UserSelector } from 'components/selectors';

export default function PhoneCardAddUsersForm({ form, initialValues }) {
  const intl = useIntl();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form]);

  return (
    <StyledForm layout="vertical" size="large" form={form}>
      <Row gutter={[32, 0]}>
        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.phoneCards.form.label.number',
            })}
            name="number"
            rules={[{ required: true }]}
          >
            <StyledInputNumber min={0} />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.phoneCards.form.label.userIds',
            })}
            name="user_ids"
            rules={[{ required: true }]}
          >
            <UserSelector
              $customType="filter"
              allowClear
              mode="multiple"
              params={{ role: ROLE.USER, page_size: 1000 }}
            />
          </StyledFormItem>
        </Col>
      </Row>
    </StyledForm>
  );
}
