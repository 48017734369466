import { Typography } from 'antd';
import { Pie } from '@ant-design/plots';
import { formatInputNumber } from 'utils/formatUtils';
import _ from 'lodash';

export default function DonutChart({ item }) {
  const { name, details, total_quantity } = item;

  const config = {
    appendPadding: 10,
    data: details,
    angleField: 'quantity',
    colorField: 'name',
    color: _.map(details, (item) => item.color),
    radius: 1,
    innerRadius: 0.55,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{percentage}',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        content: formatInputNumber(total_quantity),
      },
    },
    legend: {
      position: 'bottom',
      marker: {
        symbol: 'square',
      },
      flipPage: false,
      maxHeightRatio: 1,
      maxItemWidth: 500,
    },
  };

  return (
    <div>
      <Typography.Title level={4} style={{ textAlign: 'center' }}>
        {name}
      </Typography.Title>

      <Pie {...config} />
    </div>
  );
}
