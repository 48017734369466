import { useState } from 'react';
import { Upload } from 'antd';
import { StyledButton, StyledSpace } from 'styles/overrides';
import _ from 'lodash';

export default function UploadAudio({ onSubmit = () => {} }) {
  const [file, setFile] = useState(null);

  const handleChange = (info) => {
    setFile(_.get(info, ['fileList', '0', 'originFileObj']) ?? null);
  };

  const handleSubmit = () => {
    if (!file) {
      return;
    }

    onSubmit({ file });
  };

  return (
    <StyledSpace direction="vertical">
      <Upload
        beforeUpload={() => false}
        onChange={handleChange}
        multiple={false}
        maxCount={1}
      >
        {!file && (
          <StyledButton type="primary">Thêm câu hỏi xác minh</StyledButton>
        )}
      </Upload>

      {!!file && (
        <StyledButton type="danger" onClick={handleSubmit}>
          Xác nhận tải lên
        </StyledButton>
      )}
    </StyledSpace>
  );
}
