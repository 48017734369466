import React from 'react';
import { useIntl } from 'react-intl';
import { Form, Row, Col } from 'antd';
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledInput,
} from 'styles/overrides';
import {
  ProvinceSelector,
  DistrictSelector,
  WardSelector,
} from 'components/selectors';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';

export const getInitialValuesStoreForm = (data) => {
  if (!data) return null;

  const {
    store_name,
    common_name,
    code,
    phone,
    province,
    district,
    ward,
    street,
    market,
    address,
    status,
    lat,
    long
  } = data;

  return {
    code,
    store_name,
    common_name,
    phone,
    province_id: province?.id?.toString(),
    district_id: district?.id?.toString(),
    ward_id: ward?.id?.toString(),
    street,
    market,
    address,
    status: status?.key,
    lat,
    long
  };
};

export default function StoreForm({ initialValues, onSubmit = () => {} }) {
  const intl = useIntl();
  const [form] = Form.useForm();

  const handleValuesChange = (changedValues) => {
    if (changedValues['province_id']) {
      form.setFieldsValue({
        district_id: null,
        ward_id: null,
      });
    }

    if (changedValues['district_id']) {
      form.setFieldsValue({
        ward_id: null,
      });
    }
  };

  const handleFinish = (values) => {
    onSubmit(values);
  };

  return (
    <StyledForm
      layout="vertical"
      onValuesChange={handleValuesChange}
      form={form}
      initialValues={initialValues}
      onFinish={handleFinish}
    >
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.stores.form.label.storeCode',
            })}
            name="code"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.stores.form.label.storeName',
            })}
            name="store_name"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.stores.form.label.commonName',
            })}
            name="common_name"
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={8}>
          <StyledFormItem
            label={intl.formatMessage({ id: 'views.stores.form.label.phone' })}
            name="phone"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.stores.form.label.provinceId',
            })}
            name="province_id"
            rules={[{ required: true }]}
          >
            <ProvinceSelector />
          </StyledFormItem>
        </Col>

        <Col span={8}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.province_id !== currentValues.province_id
            }
          >
            {({ getFieldValue }) => {
              const provinceId = getFieldValue('province_id');

              return (
                <StyledFormItem
                  label={intl.formatMessage({
                    id: 'views.stores.form.label.districtId',
                  })}
                  name="district_id"
                  rules={[{ required: true }]}
                >
                  <DistrictSelector provinceId={provinceId} />
                </StyledFormItem>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.province_id !== currentValues.province_id ||
              prevValues.district_id !== currentValues.district_id
            }
          >
            {({ getFieldValue }) => {
              const provinceId = getFieldValue('province_id');
              const districtId = getFieldValue('district_id');

              return (
                <StyledFormItem
                  label={intl.formatMessage({
                    id: 'views.stores.form.label.wardId',
                  })}
                  name="ward_id"
                  rules={[{ required: true }]}
                >
                  <WardSelector
                    provinceId={provinceId}
                    districtId={districtId}
                  />
                </StyledFormItem>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={8}>
          <StyledFormItem
            label={intl.formatMessage({ id: 'views.stores.form.label.street' })}
            name="street"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={8}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.stores.form.label.address',
            })}
            name="address"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={8}>
          <StyledFormItem
            label={intl.formatMessage({ id: 'views.stores.form.label.market' })}
            name="market"
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={8}>
          <StyledFormItem
            label={intl.formatMessage({ id: 'views.stores.form.label.lat' })}
            name="lat"
            rules={[{ required: true }]}
          >
            <StyledInput/>
          </StyledFormItem>
        </Col>

        <Col span={8}>
          <StyledFormItem
            label={intl.formatMessage({ id: 'views.stores.form.label.long' })}
            name="long"
            rules={[{ required: true }]}
          >
            <StyledInput/>
          </StyledFormItem>
        </Col>

        <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC]}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <StyledButton htmlType="submit" type="danger">
              {intl.formatMessage({ id: 'common.btn.save' })}
            </StyledButton>
          </Col>
        </Permission>
      </Row>
    </StyledForm>
  );
}
