import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { Spin, Modal } from 'antd';
import { Notification } from 'components/Notification';
import {
  useUpdateQuestionAnswers,
  usePlanImages,
  usePlanAudios,
} from 'api/planApi';
import { useCreateFile, useDeleteFile, useRotateFile } from 'api/fileApi';
import PlanSurveyForm from '../PlanSurveyForm/PlanSurveyForm';
import { StyledButton, StyledCard } from 'styles/overrides';
import PlanUploadImageModal from '../PlanUploadImageModal/PlanUploadImageModal';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';
import { FILE_TYPE } from 'constants/file';
import { PlanSurveyContextProvider } from 'context/PlanSurveyContext';

const formatQuestionAnswerData = (data) => {
  const parsedData = JSON.parse(data);

  return _.reduce(
    parsedData?.question_answers,
    (acc, currentItem) => {
      const { question_id, ...restCurrentItem } = currentItem;

      acc[question_id] = restCurrentItem;

      return acc;
    },
    {},
  );
};

const groupImagesByGroupId = (images) => {
  return _.reduce(
    images,
    (acc, currentItem) => {
      const { group } = currentItem;

      if (!acc[group?.id]) {
        acc[group?.id] = {
          ...group,
          images: [],
        };
      }

      acc[group?.id].images.push(currentItem);

      return acc;
    },
    {},
  );
};

const mappingAudiosByQuestionId = (audios) => {
  return _.reduce(
    audios,
    (acc, currentItem) => {
      const { question } = currentItem;

      acc[question?.id] = currentItem;

      return acc;
    },
    {},
  );
};

export default function PlanSurvey({
  planId,
  questionAnswerData,
  questions = [],
  onShowHideQuestions,
  onUpdateQuestions,
}) {
  const intl = useIntl();
  const [isOpenAddImage, setIsOpenAddImage] = useState(false);
  const updateQuestionAnswersMutation = useUpdateQuestionAnswers({
    id: planId,
  });
  const deleteFileMutation = useDeleteFile();
  const rotateMutation = useRotateFile();
  const createFileMutation = useCreateFile();

  const {
    data: planImages,
    isLoading: isLoadingPlanImages,
    isFetching: isFetchingPlanImages,
    refetch: refetchPlanImages,
  } = usePlanImages({
    id: planId,
    options: {
      select: (response) => groupImagesByGroupId(response?.data),
    },
  });

  const {
    data: planAudios,
    isLoading: isLoadingPlanAudios,
    isFetching: isFetchingPlanAudios,
    refetch: refetchPlanAudios,
  } = usePlanAudios({
    id: planId,
    options: {
      select: (response) => mappingAudiosByQuestionId(response?.data),
    },
  });

  const parsedQuestionAnswerData = useMemo(() => {
    return formatQuestionAnswerData(questionAnswerData);
  }, [questionAnswerData]);

  const handleUpdateQuestionAnswers = (values) => {
    updateQuestionAnswersMutation.mutate(
      { ...values },
      {
        onSuccess() {
          Notification(
            'success',
            intl.formatMessage({
              id: 'message.updateSuccess',
            }),
          );
        },
        onError(error) {
          Notification(
            'error',
            error.message ||
              intl.formatMessage({
                id: 'message.commonError',
              }),
          );
        },
      },
    );
  };

  const handleDeleteImage = (imageId, fileType = FILE_TYPE.IMAGE) => {
    Modal.confirm({
      title: intl.formatMessage({ id: 'common.modal.deleteTitle' }),
      okText: intl.formatMessage({ id: 'common.btn.confirm' }),
      onOk() {
        return deleteFileMutation.mutateAsync(
          { id: imageId, params: { plan_id: planId } },
          {
            onSuccess() {
              Notification(
                'success',
                intl.formatMessage({
                  id: 'message.deleteSuccess',
                }),
              );

              if (fileType === FILE_TYPE.AUDIO) {
                refetchPlanAudios();
              } else {
                refetchPlanImages();
              }
            },
            onError(error) {
              Notification(
                'error',
                error.message ||
                  intl.formatMessage({
                    id: 'message.commonError',
                  }),
              );
            },
          },
        );
      },
      onCancel() {},
    });
  };

  const handleToggleModal = () => {
    setIsOpenAddImage((prevOpen) => !prevOpen);
  };

  const handleSuccess = () => {
    handleToggleModal();
    refetchPlanImages();
  };

  const handleRotateImage = (id, rotate) => {
    rotateMutation.mutate(
      { id, rotate: -rotate },
      {
        onSuccess: () => {
          refetchPlanImages();
        },
        onError: (error) => {
          Notification(
            'error',
            error.message ||
              intl.formatMessage({
                id: 'message.commonError',
              }),
          );
        },
      },
    );
  };

  const handleAddFile = (values) => {
    const formData = new FormData();
    formData.append('file', values.file);
    formData.append('question_id', values.questionId);
    formData.append('plan_id', planId);
    formData.append('type', FILE_TYPE.AUDIO);

    createFileMutation.mutate(formData, {
      onSuccess() {
        refetchPlanAudios();
        Notification(
          'success',
          intl.formatMessage({ id: 'message.uploadSuccess' }),
        );
      },
      onError(error) {
        Notification(
          'error',
          error.message || intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  return (
    <>
      <PlanSurveyContextProvider
        onShowHideQuestions={onShowHideQuestions}
        onUpdateQuestions={onUpdateQuestions}
        onDeleteFile={handleDeleteImage}
        onAddFile={handleAddFile}
        onRotateImage={handleRotateImage}
      >
        <Spin
          spinning={
            updateQuestionAnswersMutation.isLoading ||
            isLoadingPlanImages ||
            isFetchingPlanImages ||
            isLoadingPlanAudios ||
            isFetchingPlanAudios ||
            rotateMutation.isLoading ||
            createFileMutation.isLoading
          }
        >
          <StyledCard
            title={intl.formatMessage({ id: 'views.plans.planSurvey.title' })}
            bodyStyle={{ padding: 0 }}
            bordered={false}
            extra={
              <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC]}>
                <StyledButton type="danger" onClick={handleToggleModal}>
                  {intl.formatMessage({ id: 'common.btn.addNewImage' })}
                </StyledButton>
              </Permission>
            }
          >
            <PlanSurveyForm
              initialValues={parsedQuestionAnswerData}
              questions={questions}
              groupImages={planImages}
              mappingAudios={planAudios}
              onSubmit={handleUpdateQuestionAnswers}
            />
          </StyledCard>
        </Spin>
      </PlanSurveyContextProvider>

      <PlanUploadImageModal
        planId={planId}
        open={isOpenAddImage}
        onCancel={handleToggleModal}
        onSuccess={handleSuccess}
      />
    </>
  );
}
